import React, { useEffect, useState } from "react";
import Layout from "../Components/Layout";
import Form from "react-bootstrap/Form";
import { Container, Row, Col, FormControl, FormLabel } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LayoutNew from "../Components/LayoutNew";
import { useDispatch, useSelector } from "react-redux";
import { createPaymentIntent } from "../Redux/Actions/userActions";
import { useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import moment from "moment";
import { coachLogin } from "../Redux/Actions/coachAction";
import { loadStripe } from "@stripe/stripe-js";
import { paymentIntent } from "../Redux/Reducers/CoachSlice";

export default function InvoiceDetails() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  // // ======================================check for language=======================================

  const ingerman = useSelector((state) => state.homedata.selectedLang);
  console.log(ingerman);

  // ===========================================react state==========================================================

  const [checkbox, setCheckbox] = useState(null);
  const [tax, setTax] = useState(null);
  const [total, settotal] = useState(null);

  // ===========================================get APPOINTMENT id from store==========================================

  const appointmentId = useSelector((state) => state?.coachData?.appointmentId);

  const coachData = useSelector((state) => state?.coachData?.details);

  console.log(coachData, "coach");

  const appointmentdata = useSelector(
    (state) => state?.coachData?.appointmentData
  );

  console.log(appointmentdata, "appointmentdata");

  useEffect(() => {
    setTax(
      (
        (19 / 100) *
        (coachData?.data?.coachDetails?.rateperhour ||
          coachData?.rateperhour ||
          coachData?.coach_id?.rateperhour)
      )?.toFixed(2)
    );
  }, [coachData?.data]);

  useEffect(() => {
    console.log(tax, "tax");
    let coachRate = Number(
      coachData?.data?.coachDetails?.rateperhour ||
      coachData?.rateperhour ||
      coachData?.coach_id?.rateperhour
    );

    let sum = coachRate + Number(tax);
    console.log(sum, "rate");
    settotal(sum);
  }, [tax]);

  // ========================================fetch data by usequery===============================

  const { isPending, data, isSuccess, isError } = useQuery({
    queryKey: ["appointmentpayment"],
    queryFn: () => createPaymentIntent(appointmentId),
  });

  {
    isSuccess && dispatch(paymentIntent(data));
  }

  {
    isError && toast.error(data?.message || "Something went wrong");
  }

  console.log(data?.data?.session_id, "data?.data?.session_id");

  // ===================================make payment===================================================


  const makeStripePayment = async (sessionId, publishKey) => {

    const stripe = await loadStripe(
      publishKey
      // "pk_test_51PnGht104yRhJCICXqugdYya2AxwK4byLKfCzauTl4jSDNtOl0wb6dj5FC5BmOdDYtMkkf9ugGeRcNvFQWmRV0Fd00OmxzgXya"
      // "pk_test_51O9nKhLytwmH9rwQT3cppqEVmCHTqhxIDBHGr31ZtothHR22Yy9K0vAGXAm6Zv2aQa2yyWT69i8tzpV2CJ2KXcRH00gBf0qg3V"
      // "pk_live_51O9nKhLytwmH9rwQcpPdRUz0TnRSzAghEbrTfBFq37KZKO6ov2cGw142OYZSVIm7C99wFfKIA0apB0fqZUnhTpUY0023ho276i"
    );

    try {
      const result = await stripe.redirectToCheckout({
        sessionId: sessionId,
      });

      if (result.error) {
        console.error(result.error.message);
        toast.error(result.error.message);
      }
    } catch (error) {
      console.error("Error during checkout redirect:", error);
      toast.error(error.message);
    }
  };



  const makePayment = async () => {


    if (data?.data?.session_id) {
      makeStripePayment(data?.data?.session_id, data?.data?.publishKey);
    } else {
      toast.error("Payment session not found");
    }



    // const stripe = await loadStripe(
    //   "pk_test_51PnGht104yRhJCICXqugdYya2AxwK4byLKfCzauTl4jSDNtOl0wb6dj5FC5BmOdDYtMkkf9ugGeRcNvFQWmRV0Fd00OmxzgXya"
    //   // "pk_test_51O9nKhLytwmH9rwQT3cppqEVmCHTqhxIDBHGr31ZtothHR22Yy9K0vAGXAm6Zv2aQa2yyWT69i8tzpV2CJ2KXcRH00gBf0qg3V"
    //   // "pk_live_51O9nKhLytwmH9rwQcpPdRUz0TnRSzAghEbrTfBFq37KZKO6ov2cGw142OYZSVIm7C99wFfKIA0apB0fqZUnhTpUY0023ho276i"
    // );

    // try {
    //   const result = await stripe.redirectToCheckout({
    //     sessionId: data?.data?.session_id,
    //   });

    //   console.log(result); // Log the result for debugging
    // } catch (error) {
    //   toast.error(error.message);
    // }

    // console.log("make");

    // const { error } = await stripe.redirectToCheckout({
    //   lineItems: [{ price: 'price_12345', quantity: 1 }],
    //   mode: 'payment',
    //   // successUrl: `${window.location.origin}/success`,
    //   // cancelUrl: `${window.location.origin}/cancel`,
    // });

    // if (error) {
    //   console.error("Error redirecting to checkout:", error);
    // }
  };

  return (
    <>
      <LayoutNew>
        <div style={{ position: "relative" }} className="right-side">
          {isPending && (
            <div
              style={{
                position: "absolute",
                width: "110%",
                height: "100%",
                zIndex: "1",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                // backgroundColor: "rgba(0,0,0,0.1)",
              }}
            >
              <div class="loader"></div>
            </div>
          )}
          <div
            onClick={() => {
              if (location?.state?.back) {
                navigate("/appointments");
              } else {
                navigate(-1);
              }
            }}
            className="back-page-i"
          >
            <Link
            // to="/coaching"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="24"
                viewBox="0 0 14 24"
                fill="none"
              >
                <path
                  d="M12 22L2 12L12 2"
                  stroke="black"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Link>
          </div>

          <div className="main-content">
            <h2>
              {ingerman == "de" ? "Rechnungs-Details" : "Invoice Details"}
            </h2>
            <p className="invoice-para">
              {ingerman == "de" ? "Dein Coaching mit" : "Your Coaching with"}{" "}
              <b>
                {coachData?.data?.coachDetails?.full_name ||
                  coachData?.full_name ||
                  coachData?.coach_id?.full_name}
              </b>{" "}
              <br />
              {ingerman == "de" ? "am" : "on"}{" "}
              <b>
                {moment(
                  coachData?.date ||
                  appointmentdata?.createdAt ||
                  appointmentdata?.data?.date
                ).format("DD MMMM YYYY")}{" "}
                at{" "}
                {appointmentdata?.data?.timeSlot ||
                  appointmentdata?.slot ||
                  appointmentdata}
              </b>
            </p>
            <div className="price-invoice-detail">
              <div className="price-invoice-item">
                <p>{ingerman == "de" ? "Honorar" : "Rate"}:</p>
                <p>
                  €
                  {coachData?.data?.coachDetails?.rateperhour ||
                    coachData?.rateperhour ||
                    coachData?.coach_id?.rateperhour}
                </p>
              </div>
              <div className="price-invoice-item">
                <p>{ingerman == "de" ? "MwSt" : "VAT"}(19%):</p>
                <p>€{tax}</p>
              </div>
              <div className="price-invoice-item mt-3">
                <p>{ingerman == "de" ? "Gesamt" : "Total"}:</p>
                <p>€{total}</p>
              </div>
            </div>
            <div className="invoice-accept-txt">
              <Form>
                <Form.Check
                  type="checkbox"
                  value={checkbox}
                  onChange={(e) => setCheckbox(e.target.checked)}
                />
                {ingerman == "de" ? (
                  <Form.Label>
                    Ich stimme ausdrücklich der Ausführung des Vertrages vor Ablauf der Widerrufsfrist zu. Ich habe zur Kenntnis genommen, dass mein{" "}
                    <a style={{ cursor: "pointer" }}
                      target="_blank"
                      href="https://www.mindrepublic.com"
                      className="invoice-hl"
                    >
                      Widerrufsrecht
                    </a>{" "}
                    mit Beginn der Ausführung des Vertrages erlischt. Mit meiner Buchung akzeptiere ich die{" "}
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate("/terms-and-conditions")}
                      className="invoice-hl"
                    >
                      Allgemeinen Geschäftsbedingungen von MindRepublic
                    </span>{" "}
                    und habe die{" "}
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate("/data-protection")}
                      className="invoice-hl"
                    >
                      Datenschutzbestimmungen
                    </span>{" "}
                    zur Kenntnis genommen.
                  </Form.Label>

                ) : (
                  <Form.Label>

                    I expressly agree to the execution of the contract before
                    expiry of the withdrawal period. I have taken note that my{" "}
                    <a style={{ cursor: "pointer" }}
                      target="_blank"
                      href="https://www.mindrepublic.com" className="invoice-hl">Right of Withdrawal</a>{" "}
                    expires at beginning of the execution of the contract. with my
                    booking, I accept MindRepublic’s{" "}
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate("/terms-and-conditions")}
                      className="invoice-hl"
                    >
                      General Terms and Conditions
                    </span>{" "}
                    and have taken note of the{" "}
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate("/data-protection")}
                      className="invoice-hl"
                    >
                      Data Protection Provisions
                    </span>{" "}
                    .
                  </Form.Label>
                )}
              </Form>
            </div>
            <div className="invoice-details-link">
              <Link
                onClick={() => {
                  if (!checkbox) {
                    toast.error(ingerman == "de" ? "Bitte akzeptieren Sie die Allgemeinen Geschäftsbedingungen" : "Please accept term and conditions");
                  } else {
                    makePayment();
                  }
                }}
              // to="/appointment-created"
              >
                {ingerman == "de" ? "buchen Sie jetzt" : "Book Now"}
              </Link>
            </div>
          </div>
        </div>
      </LayoutNew>
    </>
  );
}
